
























































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { settingsServices } from "@/services/settings.service";
import { ResponsePagination } from "@/models/interface/common.interface";
import moment from "moment";
import {debounceProcess} from "@/helpers/debounce";
import {changeCurrencytoNumeric, currencyFormat} from "@/validator/globalvalidator";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
export default Vue.extend({
  data() {
    this.handleSearchSelectTable = debounceProcess(this.handleSearchSelectTable, 400)
    return {
      formatCurrencytoNumber:changeCurrencytoNumeric,
      form: this.$form.createForm(this, { name: "detailtaxinvoiceserialnumber" }),
      limit:10 as number,
      page:0 as number,
      dataListTaxInvoiceSerialNumber: [] as any[],
      listDeletedTax: [] as any[],
      labelCol: { span: 6 },
      selectedRowKeys: [] as number[],
      wrapperCol: { span: 16 },
      loadingTable: false as boolean,
      columnsTable: [
        {
          title: "Declaration Letter (DPJ) Number",
          dataIndex: "declarationLetterNumber",
          key: "declarationLetterNumber",
          width: 300,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Delcaration Letter Date",
          dataIndex: "declarationLetterDate",
          key: "declarationLetterDate",
          width: 300,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Tax Invoice Serial Number",
          dataIndex: "taxInvoiceSn",
          key: "taxInvoiceSn",
          width: 300,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Invoice Number",
          dataIndex: "invoiceNumber",
          key: "invoiceNumber",
          width: 300,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Customer Name",
          dataIndex: "customer",
          key: "customer",
          width: 300,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Tax Invoice Number",
          dataIndex: "taxInvoiceNumber",
          key: "taxInvoiceNumber",
          width: 300,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: "Invoice Date",
          dataIndex: "invoiceDate",
          key: "invoiceDate",
          width: 300,
          scopedSlots: { customRender: "isNull" },
        },
      ] as any,
    };
  },
  mounted(){
    this.getDetailData()
  },
  methods: {
    moment,
    getDetailData(){
      let params = {
        limit : this.limit,
        page : this.page,
        search: `invoiceSerialNumberHeader.secureId~${this.$route.params.id}`
      }as RequestQueryParamsModel
      this.loadingTable = true
      settingsServices.detailOfTaxInvoiceSerialNumber(params).then( (res:any) => {
          res.data.map((item,index) => (item.key = index , item.declarationLetterDate = moment(item.declarationLetterDate).format(DEFAULT_DATE_FORMAT) , item.invoiceDate = item.invoiceDate ? moment(item.invoiceDate).format(DEFAULT_DATE_FORMAT) : null))
          this.dataListTaxInvoiceSerialNumber = res  
      }).finally(()=> this.loadingTable = false)
    },
    handleSearchSelectTable (value , record , name) {
      console.log(value , record , name);
    },
    showConfirmation () {
      if(this.selectedRowKeys.length > 0) {
        this.$confirm({
          title: `Do you want to delete these items?`,
          content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
          onOk: () => {
            this.handleDeleteRow()
          },
          onCancel() {return;},
        });
      } else {
        this.$notification.error({
          message: 'Error',
          description: 'Select at least one row to delete',
        })
      }
    },
    handleSelectTable(value, key, col, recordOptions): void {
      this.dataListTaxInvoiceSerialNumber[key] = {...this.dataListTaxInvoiceSerialNumber[key],[col] :value};
      this.dataListTaxInvoiceSerialNumber = this.dataListTaxInvoiceSerialNumber.slice();
    },
    save():void{
      console.log('save');
    },
    handleInput (value, key, objectColInput, objectColInputName: string, onEvt: string) {
      let formatedVal: string | number = value;
      if (onEvt === 'onBlur') {
        formatedVal = currencyFormat(value);
      } else if (onEvt === 'onFocus') {
        formatedVal = this.formatCurrencytoNumber(value);
      }
      this.dataListTaxInvoiceSerialNumber[key][objectColInput.name] = formatedVal
      this.dataListTaxInvoiceSerialNumber = this.dataListTaxInvoiceSerialNumber.slice()      
    },
    handleDateMonth (value, key, objectColDate,dateString) {
        console.log(value, key, objectColDate,dateString);
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleDeleteRow () {
      this.dataListTaxInvoiceSerialNumber = this.dataListTaxInvoiceSerialNumber.filter((data) => {
        if (this.selectedRowKeys.includes(data.key)){
          this.listDeletedTax.push(data.id)
        }
        return !this.selectedRowKeys.includes(data.key)
      })
      this.dataListTaxInvoiceSerialNumber.map((data, index) => data.key = index)
      this.dataListTaxInvoiceSerialNumber = this.dataListTaxInvoiceSerialNumber.slice()
      this.selectedRowKeys = []
    },
    handleAddRow():void{
    console.log('add');
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 0;
      this.getDetailData();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page -1;
      this.getDetailData();
    },
    cancel():void{
       this.$router.push(`/settings/taxinvoiceserialnumber`)
    }
  },
});
